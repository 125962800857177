<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidaciones Internas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('cs.liquidacionesInternos.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#modal-form-liquidacion-export"
                        @click="$refs.CsLiquidacionExport.resetFilter()"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <div class="form-group col-md-4">
                    <label>Bloques</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="bloque"
                      placeholder="Bloques"
                      label="nombre"
                      :options="listasForms.bloques"
                      :filterable="false"
                      @search="buscarBloques"
                      @input="getIndex()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresas..."
                      label="razon_social"
                      :options="listasForms.empresas"
                      :filterable="false"
                      @search="buscarEmpresas"
                      @input="getIndex()"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Inicial</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      v-model="filtros.estado"
                      class="form-control form-control-sm"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado Proceso</label>
                    <select
                      v-model="filtros.estado_proceso"
                      class="form-control form-control-sm"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estadosProceso"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr class="text-nowrap bg-dark">
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Bloques</th>
                      <th>Empresa</th>
                      <th>Fecha Inicial</th>
                      <th>Fecha Final</th>
                      <th>Estado</th>
                      <th>Estado Proceso</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(liqui, index) in liquidaciones.data"
                      :key="index"
                    >
                      <td>{{ liqui.id }}</td>
                      <td>{{ liqui.nombre }}</td>
                      <td class="text-center">
                        <span
                          class="badge badge-info m-1 p-1"
                          v-for="bloq in liqui.bloques"
                          :key="bloq.id"
                        >
                          {{ bloq.nombre }}
                        </span>
                      </td>
                      <td>{{ liqui.empresa.razon_social }}</td>
                      <td class="text-center">{{ liqui.fecha_ini }}</td>
                      <td class="text-center">{{ liqui.fecha_fin }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            liqui.estado == 1
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                        >
                          {{ liqui.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            liqui.estado_proceso == 1
                              ? 'bg-info'
                              : liqui.estado_proceso == 2
                              ? 'bg-warning'
                              : liqui.estado_proceso == 3
                              ? 'bg-olive'
                              : liqui.estado_proceso == 4
                              ? 'bg-navy'
                              : liqui.estado_proceso == 5
                              ? 'bg-success'
                              : 'bg-secondary'
                          "
                        >
                          {{ liqui.nEstadoProceso }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn bg-navy btn-sm"
                            @click="edit(liqui)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-teal btn-sm"
                            data-toggle="modal"
                            data-target="#Modal_firmas"
                            @click="getDataFirmas(liqui)"
                            v-if="
                              ($store.getters.can(
                                'cs.liquidacionesInternos.firmaContratista'
                              ) ||
                                // $store.getters.can(
                                //   'cs.liquidacionesInternos.firmaInterventor'
                                // ) ||
                                $store.getters.can(
                                  'cs.liquidacionesInternos.firmaAdministrador'
                                )) &&
                                liqui.estado_proceso == 2
                            "
                          >
                            <i class="fas fa-signature"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-indigo btn-sm"
                            @click="printTicket(liqui)"
                            v-if="
                              $store.getters.can(
                                'cs.liquidacionesInternos.ticket'
                              )
                            "
                          >
                            <i class="far fa-file-pdf"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'cs.liquidacionesInternos.delete'
                              ) && liqui.estado_proceso == 1
                            "
                            @click="destroy(liqui.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="liquidaciones.total">
                  Mostrando del <b>{{ liquidaciones.from }}</b> al
                  <b>{{ liquidaciones.to }}</b> de un total:
                  <b>{{ liquidaciones.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="liquidaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <CsLiquidacionExport ref="CsLiquidacionExport" />
        <!-- Modal de firmas -->
        <div class="modal fade" id="Modal_firmas">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ modalTitle }}</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModalFirmas"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="form-group col-md-6">
                    <h5>Firma Contratista</h5>
                    <div
                      class="row"
                      v-if="
                        $store.getters.can(
                          'cs.liquidacionesInternos.firmaContratista'
                        ) && !modalData.contratista
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              Para firmar la liquidación por favor digite su
                              clave.
                            </strong></small
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="sign_password">Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.contratista.pass"
                          :class="
                            $v.formFirmas.contratista.pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="re_sign_password">Confirme Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.contratista.re_pass"
                          :class="
                            $v.formFirmas.contratista.re_pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label
                        ><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="formFirmas.contratista.observacion"
                          :class="
                            $v.formFirmas.contratista.observacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button
                          type="button"
                          title="Guardar firma"
                          data-toggle="tooltip"
                          class="btn btn-sm bg-success"
                          @click="saveFirma(1)"
                          v-if="
                            $store.getters.can(
                              'cs.liquidacionesInternos.firmaContratista'
                            ) && !$v.formFirmas.contratista.$invalid
                          "
                        >
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        !$store.getters.can(
                          'cs.liquidacionesInternos.firmaContratista'
                        ) && !modalData.contratista
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              A la espera de aprobación del Contratisa.
                            </strong></small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="modalData.contratista && modalData.contratista.user"
                    >
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{ modalData.contratista.user.name }}</strong>
                        </p>
                        <p
                          class="text-center mr-5 ml-5"
                          v-if="modalData.contratista.user.link_firma"
                        >
                          <img
                            :src="
                              uri_docs + modalData.contratista.user.link_firma
                            "
                            class="img-fluid"
                          />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
                            modalData.contratista.fecha_firma
                          }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.contratista.observacion }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group col-md-4">
                    <h5>Firma Interventor del Contrato</h5>
                    <div
                      class="row"
                      v-if="
                        $store.getters.can(
                          'tep.liquidacionesInternos.firmaInterventor'
                        ) && !modalData.interventor
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              {{
                                $store.getters.getAuthAzure
                                  ? `La sesión esta iniciada con Azure.`
                                  : `Para firmar la liquidación por favor digite su clave.`
                              }}
                            </strong></small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="sign_password">Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.interventor.pass"
                          :class="
                            $v.formFirmas.interventor.pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="re_sign_password">Confirme Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.interventor.re_pass"
                          :class="
                            $v.formFirmas.interventor.re_pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label
                        ><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="formFirmas.interventor.observacion"
                          :class="
                            $v.formFirmas.interventor.observacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button
                          type="button"
                          title="Guardar firma"
                          data-toggle="tooltip"
                          class="btn btn-sm bg-success"
                          @click="saveFirma(2)"
                          v-if="
                            $store.getters.can(
                              'cs.liquidacionesInternos.firmaInterventor'
                            ) && !$v.formFirmas.interventor.$invalid
                          "
                        >
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        !$store.getters.can(
                          'tep.liquidacionesInternos.firmaInterventor'
                        ) && !modalData.interventor
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              A la espera de aprobación del interventor del
                              Contrato.
                            </strong></small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="modalData.interventor && modalData.interventor.user"
                    >
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{ modalData.interventor.user.name }}</strong>
                        </p>
                        <p
                          class="text-center mr-5 ml-5"
                          v-if="modalData.interventor.user.link_firma"
                        >
                          <img
                            :src="
                              uri_docs + modalData.interventor.user.link_firma
                            "
                            class="img-fluid"
                          />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
                            modalData.interventor.fecha_firma
                          }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.interventor.observacion }}
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group col-md-6">
                    <h5>Firma Administrador del Contrato</h5>
                    <div
                      class="row"
                      v-if="
                        $store.getters.can(
                          'cs.liquidacionesInternos.firmaAdministrador'
                        ) && !modalData.administrador
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              {{
                                $store.getters.getAuthAzure
                                  ? `La sesión esta iniciada con Azure.`
                                  : `Para firmar la liquidación por favor digite su clave.`
                              }}
                            </strong></small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="sign_password">Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.administrador.pass"
                          :class="
                            $v.formFirmas.administrador.pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="re_sign_password">Confirme Contraseña</label
                        ><input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas.administrador.re_pass"
                          :class="
                            $v.formFirmas.administrador.re_pass.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label
                        ><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="formFirmas.administrador.observacion"
                          :class="
                            $v.formFirmas.administrador.observacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button
                          type="button"
                          title="Guardar firma"
                          data-toggle="tooltip"
                          class="btn btn-sm bg-success"
                          @click="saveFirma(2)"
                          v-if="
                            $store.getters.can(
                              'cs.liquidacionesInternos.firmaAdministrador'
                            ) && !$v.formFirmas.administrador.$invalid
                          "
                        >
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        !$store.getters.can(
                          'cs.liquidacionesInternos.firmaAdministrador'
                        ) && !modalData.administrador
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              A la espera de aprobación del Administrador del
                              Contrato.
                            </strong></small
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        modalData.administrador && modalData.administrador.user
                      "
                    >
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{
                            modalData.administrador.user.name
                          }}</strong>
                        </p>
                        <p
                          class="text-center mr-5 ml-5"
                          v-if="modalData.administrador.user.link_firma"
                        >
                          <img
                            :src="
                              uri_docs + modalData.administrador.user.link_firma
                            "
                            class="img-fluid"
                          />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
                            modalData.administrador.fecha_firma
                          }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.administrador.observacion }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import { required, sameAs } from "vuelidate/lib/validators";
import CsLiquidacionExport from "./CsLiquidacionExport";

export default {
  name: "CsLiquidacionesinternos",
  components: {
    pagination,
    vSelect,
    Loading,
    CsLiquidacionExport,
  },

  data() {
    return {
      cargando: false,
      id: null,
      modalTitle: null,
      modalData: [],
      bloque: null,
      empresa: null,
      filtros: {
        bloque_id: null,
        empresa_id: null,
        prueba: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
        estado_proceso: null,
      },
      liquidaciones: {},
      listasForms: {
        estados: [],
        empresas: [],
        bloques: [],
        estadosProceso: [],
      },
      form: {
        id: null,
        n_pago: null,
        estado_proceso: 5,
      },
      formFirmas: {
        contratista: {
          id: null,
          tipo_firma: 1,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        /*         interventor: {
          id: null,
          tipo_firma: 2,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        }, */
        administrador: {
          id: null,
          tipo_firma: 2,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    /*     let firm_interventor = {};
     */ let firm_administrador = {};
    if (!this.$store.getters.getAuthAzure) {
      /*       firm_interventor = {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      }; */
      firm_administrador = {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      };
    } else {
      /*       firm_interventor = {
        observacion: {
          required,
        },
      }; */
      firm_administrador = {
        observacion: {
          required,
        },
      };
    }

    return {
      form: {
        id: {
          required,
        },
        n_pago: {
          required,
        },
        estado_proceso: {
          required,
        },
      },
      formFirmas: {
        contratista: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
        /*         interventor: firm_interventor,
         */ administrador: firm_administrador,
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      const me = this;

      // Se ajustan los filtros de los v-select
      this.filtros.bloque_id = this.bloque ? this.bloque.id : null;
      this.filtros.empresa_id = this.empresa ? this.empresa.id : null;

      axios
        .get("/api/cs/liquidacionesInternos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.liquidaciones = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [4],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarBloques(search) {
      let me = this;
      me.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEstadosProceso() {
      axios.get("/api/lista/184").then((response) => {
        this.listasForms.estadosProceso = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Cs/LiquidacionesInternosForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(liqui) {
      return this.$router.push({
        name: "/Cs/LiquidacionesInternosForm",
        params: { accion: "Editar", data_edit: liqui, id: liqui.id },
      });
    },

    async printTicket(ticket) {
      let flag = true;
      if (
        !ticket.fecha_radicacion &&
        ticket.estado == 2 &&
        ticket.estado_proceso == 3
      ) {
        await this.$swal({
          title: "Está seguro de generar el Ticket?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Generar!",
        }).then((result) => {
          if (!result.value) {
            flag = false;
          }
        });
      }

      if (flag) {
        this.cargando = true;
        const data = {
          ticket_id: ticket.id,
          fecha_radicacion: ticket.fecha_radicacion,
          estado: ticket.estado,
          estado_proceso: ticket.estado_proceso,
        };
        axios
          .get(`/api/cs/liquidacionesInternos/ticket`, {
            params: data,
          })
          .then((response) => {
            let data = response.data;
            this.cargando = false;
            this.getIndex();
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    exportExcel(id) {
      /* const data = { id };
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/liquidacionesInternos/excel",
        data,
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        }); */
    },

    getDataFirmas(liqui) {
      this.resetForm();
      this.id = liqui.id;
      this.modalData = [];
      this.modalTitle = `Aprobación de Liquidación`;
      const firmas = liqui.cs_liquidaciones_internas_firmas;

      // Se valida si existen firmas
      if (firmas.length > 0) {
        firmas.forEach((firma) => {
          switch (firma.tipo_firma) {
            case 1:
              this.modalData.contratista = firma;
              break;

            /*             case 2:
              this.modalData.interventor = firma;
              break; */

            case 2:
              this.modalData.administrador = firma;
              break;
          }
        });
      }
    },

    saveFirma(tipo_firma) {
      const me = this;
      this.cargando = true;
      let form = null;
      switch (tipo_firma) {
        /*        case 2:
          this.formFirmas.interventor.cs_liquidacion_interna_id = this.id;
          this.formFirmas.interventor.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.interventor;
          break; */
        case 1:
          this.formFirmas.contratista.cs_liquidacion_interna_id = this.id;
          this.formFirmas.contratista.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.contratista;
          break;
        case 2:
          this.formFirmas.administrador.cs_liquidacion_interna_id = this.id;
          this.formFirmas.administrador.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.administrador;
          break;
      }
      form.tipos_vehiculos = this.tipos_vehiculos;
      form.operaciones = this.operaciones;
      form.areas = this.areas;
      form.loginAzure = this.$store.getters.getAuthAzure;

      axios
        .post("/api/cs/liquidacionesInternos/firmas", form)
        .then((response) => {
          if (!response.data.user_error) {
            this.cargando = false;
            me.getIndex();
            this.$refs.closeModalFirmas.click();

            me.$swal({
              icon: "success",
              title: "La liquidación fue autorizada exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.cargando = false;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    resetForm() {
      this.formFirmas = {
        contratista: {
          id: null,
          tipo_firma: 1,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        /*         interventor: {
          id: null,
          tipo_firma: 2,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        }, */
        administrador: {
          id: null,
          tipo_firma: 2,
          cs_liquidacion_interna_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      };
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar esta liquidación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/cs/liquidacionesInternos/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  beforeMount() {
    this.getEstados();
    this.getEstadosProceso();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
